<template>
	<div id="app">
		<div class="container">
			<div id="labelThesis">Bachelorarbeit</div>
			<div id="labelName">Jonas Schimmer</div>
			<div id="labelDownload">
				<a :href="baURL">
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					Download</a
				>
			</div>
		</div>
	</div>
</template>

<script>
	import { Storage } from 'aws-amplify'

	export default {
		name: 'App',
		data() {
			return {
				baURL: null,
			}
		},
		methods: {},
		mounted() {
			Storage.get('BA-SCHIMMER.zip').then((data) => {
				console.log(data)
				this.baURL = data
			})
		},
	}
</script>

<style>
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}

	#app {
		font-family: Palatino, serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #000000;
	}

	div.container {
		text-align: center;
		margin-top: 200px;
	}

	div#labelThesis {
		font-size: 35px;
	}
	div#labelName {
		margin-top: 25px;
	}
	div#labelDownload {
		margin-top: 50px;
	}

	/* Button-Style */
	a {
		position: relative;
		display: inline-block;
		text-decoration: none;
		padding: 15px 20px;
		color: #000000;
		letter-spacing: 0.5px;
		font-size: 15px;
	}

	a::after,
	a::before {
		border: 2px solid #000000;
		content: '';
		position: absolute;
		width: calc(100% - 4px);
		height: calc(100% - 4px);
		left: 0;
		bottom: 0;
		z-index: -1;
		transition: transform 0.3s ease;
	}
	a:hover::after {
		transform: translate(-5px, -5px);
	}
	a:hover::before {
		transform: translate(5px, 5px);
	}
</style>
