/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:d06f6b9d-39ae-4d66-bef7-1a53f45db771",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_Ws0y7jSed",
    "aws_user_pools_web_client_id": "16gk29t43cthdsjblvunit2m5d",
    "oauth": {},
    "aws_user_files_s3_bucket": "badownloadbucket192316-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
