import Vue from 'vue'
import App from './App.vue'

import Amplify from 'aws-amplify'
import '@aws-amplify/ui-vue'
import AWSConfig from './aws-exports'

Amplify.configure(AWSConfig)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
